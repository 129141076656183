.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.container_header {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
}
.container_back {
  margin-bottom: 20px;
}
.container_button {
  margin-top: auto;
  align-self: flex-end;
  margin-right: 24px;
}
/*
.container_content {
}
*/
