.Main_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .UpcomingDatesHome_container {
    width: 100%;
  }
  .EnrollmentSummary_container {
    width: 100%;
    margin-top: 20px;
  }
  .InitiativeStatus_container{
    width: 100%;
    margin-top: 20px;
  }
  .EnrollmentSiteLocations_container{
    width: 100%;
    margin-top: 20px;
  }
  .PerformanceValidationSummary_container{
    width: 100%;
    margin-top: 20px;
  }
  