.card {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #dddddd;
  border-radius: 20px !important;
}

.container {
  padding: 20px !important;
}

.LongTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold !important;
}

.rowContainer {
  display: flex;
  width: 100%;
}

.cardList {
  width: 100%;
  box-shadow: none !important;
  border: none;
}

.initiativeName {
  font-size: 24px;
  line-height: 36px;
  font-weight: bolder !important;
  padding-top: 30px;
}

.activityDescription {
  font-size: 16px;
  line-height: 18px;
  color: #000000;  
}

.activityEndDate {
  font-size: 16px;
  line-height: 18px;
}

.activityDate {
  font-size: 24px;
  line-height: 28.8px;
}
.noDataMessage{
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}