.grid-container {
  display: flex;
  justify-content: flex-start;
  min-height: 90vh;
  margin-top: -20px;
  margin-bottom: -20px;
  flex-direction: column;
}
.main-content {
  display: flex;
  flex-direction: row;
}

.bottomButtonContainer {
  padding-top: 40px;
}

h2 {
  /* Headings/H1 Heading */
  font-style: normal;
  font-weight: 600;
  /* font-size: 40px; */
  line-height: 120%;
  /* identical to box height, or 48px */
  color: #000000;
}
h3 {
  font-style: normal;
  font-weight: 600;
  /* font-size: 40px; */
  line-height: 120%;
  /* identical to box height, or 48px */
  color: #000000;
}

aside {
  flex: 0 0 1%; /* Sidebar width, adjust as needed */
}

main {
  padding: 20px;
  grid-area: content;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex: 1;

  overflow-y: hidden;
  display: block;
  margin: 0 auto;
}

/* Apply max-width: 1200px to all <main> elements except those with .initiativeQues class */
main:not(:has(.initiativeQues)) {
  max-width: 1200px;
  padding: 20px;
}

.react-data-table {
  overflow-x: hidden !important;
}
