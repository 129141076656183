@import "../variables.scss";

svc-page {
  display: block;
}

.svc-page {
  margin-top: calcSize(2);

  .sd-page__title {
    margin: calcSize(3) 0 0 0;
    color: var(--ctr-survey-page-header-title-color-placeholder, $foreground-dim);
    gap: calcSize(0.5);
  }

  .sd-page__num {
    color: var(--ctr-survey-page-header-title-color-placeholder, $foreground-light);
  }

  .svc-panel__placeholder {
    color: var(--ctr-survey-page-placeholder-text-color, $foreground-light);
  }
}

.svc-page__content {
  position: relative;
  border-radius: calcSize(1);
  padding: calcSize(1) calcSize(0) calcSize(3);
  box-sizing: border-box;
  width: 100%;
  outline: none;
  transition: background $creator-transition-duration, box-shadow $creator-transition-duration;

  .sd-page {
    margin: 0;
    padding: calcSize(1) calcSize(3) 0;
  }

  &>.svc-question__drag-area {
    padding-top: calcSize(1.25);
  }
}

.svc-page__content--dragged {
  opacity: 0.25;
}

.svc-page__content-actions {
  position: absolute;
  opacity: 0;
  top: calcSize(1);
  inset-inline-end: 0; // right
  padding: 0 calcSize(1.5) 0 calcSize(1);
  z-index: 1;
  transition: opacity $creator-transition-duration;

  .sv-action-bar-separator {
    margin: 0 calcSize(1);
  }

  h4.sd-title {
    .sv-string-editor {
      &[aria-placeholder]:empty:before {
        color: var(--ctr-survey-page-header-title-color-placeholder, $foreground-light);
      }
    }
  }
}

.svc-page__content-actions {
  .sv-action-bar-item {
    border-radius: var(--ctr-survey-page-toolbar-item-corner-radius, 4px);
    background-color: transparent;
    color: var(--ctr-survey-page-toolbar-item-text-color, $foreground);
    transition: background-color $creator-transition-duration;
  }

  .sv-action-bar-item__icon {
    use {
      fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
    }
  }

  //hovered state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled,
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):focus:enabled {
    background-color: var(--ctr-survey-question-panel-toolbar-item-background-color-hovered, $background-dim);
  }

  //pressed state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):active:enabled {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 0.5);
  }

  //disabled state
  .sv-action-bar-item:disabled {
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-disabled, 0.25);
  }

  .sv-action-bar-item--secondary {
    .sv-action-bar-item__icon use {
      fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
    }
  }

  .sv-action-bar-item--active {
    .sv-action-bar-item__icon use {
      fill: var(--ctr-survey-question-panel-toolbar-item-icon-color, $secondary);
    }
  }

  .sv-action-bar-item-dropdown {
    border-radius: calcCornerRadius(0.5);
    background-color: transparent;
  }

  .sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
    background-color: var(--ctr-survey-page-toolbar-item-background-color-selected, $background-dim);
    opacity: var(--ctr-survey-question-panel-toolbar-item-opacity-pressed, 50%);
  }
}

.svc-page__content:focus,
.svc-hovered.svc-page__content {
  box-shadow: 0 0 0 2px $secondary-light;
  background: var(--ctr-survey-page-background-color-hovered, $secondary-backcolor-semi-light);
}
.svc-page__content--new.svc-page__content--new {
  box-shadow: none;
  background-color: transparent;
}

.svc-page__content--selected,
.svc-hovered.svc-page__content {
  .svc-page__content-actions {
    opacity: 1;
  }

  &>.svc-question__drag-area {
    z-index: 1;

    .svc-question__drag-element {
      opacity: var(--ctr-survey-page-drag-indicator-opacity, 0.5);
    }
  }
}

.svc-page__content--selected.svc-page__content--selected {
  box-shadow: 0 0 0 2px $secondary;
  background: $secondary-backcolor-semi-light;
}


.svc-page__content--animation-running {
  .svc-row {
    .svc-question--enter {
      animation: none;
    }  
    .svc-question--leave {
      animation-name: moveInWithOverflow;
      animation-direction: reverse;
      animation-fill-mode: forwards;
      animation-timing-function: $reverse-ease-out;
      animation-duration: $svc-collapse-move-out-duration;
      animation-delay: $svc-collapse-move-out-delay;
    }
  } 
}
.svc-creator--disable-animations {
  .svc-row {
    .svc-question--leave {
      animation: none;
     }
  }
}
.svc-page__content--collapse-onhover, .svc-page__content--collapse-always {
  outline: 2px transparent dashed;
  transition: outline-color $creator-transition-duration;
  &.svc-page__content--selected,
  &.svc-page__content.svc-hovered {
    outline-color: transparent;
  }
  &.svc-page__content--collapsed-drag-over-inside {
    box-shadow: 0 0 0 2px $primary;
    background: $primary-light;
    animation: collapsed-drag-over-inside-blinking 1s infinite;
    outline-color: transparent;
  }
} 
.svc-page__content--collapsed {
  outline-color: $border;
}

.svc-creator--mobile {
  .svc-page__content {
    &::after {
      margin: 0;
      border-radius: calcSize(1);
    }
  }

  &.svc-creator .svc-page .svc-page__content--selected,
  &.svc-creator .svc-page .svc-page__content--selected:focus,
  .svc-hovered.svc-page__content {
    &.svc-page__content--selected {
      box-shadow: 0 0 0 2px inset $secondary;

      box-shadow: 0 0 0 2px inset var(--ctr-survey-page-border-color-selected, $secondary);
      background: var(--ctr-survey-page-background-color-selected, $secondary-backcolor-semi-light);

      .svc-page__content-actions {
        display: block;
      }
    }
  }

  .svc-page__content:not(.svc-page__content--new):focus,
  .svc-hovered.svc-page__content:not(.svc-page__content--new) {
    box-shadow: 0 0 0 2px inset $secondary-light;
  }
}

.svc-page__footer {
  overflow: visible;
  margin-left: calcSize(3);
  margin-right: calcSize(3);
  margin-top: calcSize(2);
  gap: calcSize(2);

  .sv-action {
    flex: 1 1 0;
  }

  .svc-btn {
    flex-grow: 1;
    border: none;
  }
}

.svc-element__add-new-question {
  .svc-add-new-item-button__text {
    margin-left: calcSize(6);
  }
}

.svc-element__add-new-question {
  -webkit-tap-highlight-color: transparent;
}

.svc-add-new-item-button__text {
  @include ctrDefaultBoldFont;
  color: $foreground;
}

.svc-element__question-type-selector {
  appearance: none;
  display: flex;
  height: calcSize(5);
  padding: calcSize(1);
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-inline-end: calcSize(1); // margin-right
  outline: none;
  transition: background-color $creator-transition-duration;

  use {
    fill: var(--ctr-library-contextual-button-icon-color, $foreground-light);
  }

  &:hover {
    background-color: var(--ctr-button-contextual-button-background-color-hovered, $background-dim);
  }

  &:focus {
    background-color: var(--ctr-button-contextual-button-background-color-focused, $primary-light);
  }
}

.svc-page--drag-over-empty:after {
  content: " ";
  position: absolute;
  background: $secondary;
  left: calcSize(3);
  bottom: calcSize(11.5);
  width: calc(100% - 48px);
  height: 2px;
}

.svc-page--drag-over-empty-no-add-button:after {
  bottom: auto;
}

.svc-page__placeholder_frame {
  min-width: auto;
  max-width: 100%;
  margin-top: calcSize(2);
  padding: 0 calcSize(3);

  .svc-panel__placeholder_frame {
    padding: calcSize(6) calcSize(8);
    height: auto;
  }
}

.svc-element__add-new-question,
.svc-question__adorner {
  .sv-list__item.sv-list__item--disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  .sv-popup--overlay {
    .sv-list-item__marker-icon {
      display: none;
    }

    .sv-list__item-body {
      pointer-events: none;
    }

    .sv-list__item.sv-list__item--selected.sv-list__item--group>.sv-list__item-body {
      @include ctrDefaultBoldFont;
      background-color: $primary;
      color: $primary-foreground;
    }

    .sv-list__item.sv-list__item--selected.sv-list__item--group>.sv-list__item-body use {
      fill: $background;
    }
  }
}

.svc-page__content--collapsed {
  &>.sv-action-bar {
    display: none;
  }

  .svc-page__placeholder_frame {
    display: none;
  }

  .svc-page__content-actions {
    display: block;
  }

  .sd-page {
    display: flex;

    &>* {
      display: none;
    }

    .sd-title {
      display: block;
    }
  }
}

.svc-page__content.svc-page__content--collapsed {
  .sd-page {
    padding-bottom: 0;
  }
}

.svc-creator .svc-page {
  .svc-page__content--collapse-always {
    .svc-page__content-actions {
      opacity: 1;

      .sv-action {
        visibility: hidden;
      }

      .sv-action.sv-action-bar-item--collapse {
        visibility: visible;

        .sv-action-bar-separator {
          visibility: hidden;
        }
      }
    }

    &.svc-page__content--selected,
    &.svc-hovered {
      .svc-page__content-actions {
        .sv-action {
          visibility: visible;

          .sv-action-bar-separator {
            visibility: visible;
          }
        }
      }
    }
  }
}