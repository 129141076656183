.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableHeader {
  padding-left: 20px;
  padding-right: 50px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.tableHeaderName {
  flex: 2.7;
}
.tableHeaderStartDate {
  flex-grow: 1;
}
.tableHeaderEndDate {
  flex-grow: 1;
}
