.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .container_header_title h1 {
    margin-right: 10px; /* Optional: Add margin between the title and filter option */
  }
 