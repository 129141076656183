.required_error {
  /* Auto layout */

  display: table-row;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;

  width: 400px;
  height: 32px;

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.required_error span {
  width: 350px;
  height: 20px;

  /* Paragraph Small / Regular */

  /* font-family: "Source Sans Pro"; */
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  /* Status / Error */

  color: #da1414 !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.required_error svg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 2px 0px 0px;

  width: 16px;
  height: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
