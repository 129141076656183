.ListItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding-bottom: 0px;
}

.ListItemRow {
  display: flex;
  flex-direction: row;
}

.ListItemButton {
  text-align: right;
  background-color: transparent;
}

.container {
  padding-left: 5px;
  flex: 2;
}
.container_completed{
  flex: 2;
  padding-left: 35px;
}

.name {
  font-weight: 600;
  display: flex;
  margin-top: 10px;
  line-height: 25px;
  font-size: 25px;
  align-items: center;
}
