 .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
  }
  .container_back {
    margin-bottom: 20px;
  }
  .noDataMessage{
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 50px;
  }
  .addUserButton{
      max-width: 130px!important;
      min-width: 130px !important;
  }

  .searchButton{
    max-width: 130px!important;
    min-width: 130px !important;
  }
  .searchBoxContainer{
    display: flex!important;
    align-items: center!important;
  }
.searchButtonContainer{
  flex: 0 0 auto!important;
  display: flex!important;
  align-items: center!important;
  margin-left: 10px!important;
}