    .initiativeChart {
      border: 1px solid #dddddd;
      width: 100%;
      border-radius: 20px !important;
      box-shadow: none !important;
    }

    .LongTitle {
      font-size: 28px!important;
      line-height: 30px!important;
      font-weight: 600!important;
      text-align: center;
      margin-bottom: 10px!important;
    }

    .shortTitle {
      font-size: 18px!important;
      line-height: 24px!important;
      font-weight: 600!important;
      margin-bottom: 20px!important;
    }