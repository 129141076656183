.sidebar {
  background-color: #f0f0f0;
  color: black;
  display: flex;
  flex-direction: column;

  width: 72px;
  font-size: 14px;
  background: #f0f0f0;
  box-shadow: inset -1px 0px 2.5px rgba(0, 0, 0, 0.15);
  height: auto;
  bottom: 0%;
  top: 72px;
}



