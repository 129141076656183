.container {
  background-color: #feff56;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
}
.viewAsUserText {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.noticeIcon {
  min-width: 20px;
  float: left;
  padding: 0px 10px 0px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roundeButtonMinWidth {
  min-width: 200px;
}

.redText {
  color: #ff0004;
}
.userInfoText {
  font-weight: 700;
}
