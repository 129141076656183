.searchFilter {
  border-radius: 50%;
}

.cardContainer {
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-top: 10px;
}

.cardContainer > div {
  min-height: 200px; 
  margin: 8px;
}
.cardContainer > div > div {
  min-height: 200px;
}

.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  height: 100%;
}

.roundBtn{
  margin-right: 20px;
  display: inline;
}

.exportButton{
  max-width: 104px!important;
}
.searchButton{
  max-width: 104px!important;
}