.LongSummary{
    font-family: "Source Sans Pro";
    font-size:  16px;
    font-weight: 400;
    line-height: 18.52px;
    letter-spacing: 0px;
    margin-top: 10px;
    width: calc(100% - 84px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.LongTitle{
    font-family: "Source Sans Pro";
    width: calc(100% - 84px);
    font-size: 24px;
    font-weight: 600;
}

.icon{
    position: relative;
}