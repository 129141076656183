@import "../variables.scss";
@import "./blocks/variables.scss";
@import "blocks/spg-action-bar.scss";
@import "blocks/spg-question.scss";
@import "blocks/spg-input.scss";
@import "blocks/spg-dropdown.scss";
@import "blocks/spg-comment.scss";
@import "blocks/spg-panel.scss";
@import "blocks/spg-checkbox.scss";
@import "blocks/spg-matrixdynamic.scss";
@import "blocks/spg-table.scss";
@import "blocks/spg-question-group.scss";
@import "blocks/spg-color-editor.scss";
@import "blocks/spg-file-edit.scss";
@import "blocks/spg-link";
@import "blocks/spg-theme-builder.scss";
@import "blocks/spg-overriding.scss";
@import "blocks/spg-search-editor.scss";
@import "blocks/spg-buttongroup.scss";

.spg-root-modern {
  position: relative;
  width: 100%;
  border-inline-end: 1px solid $border;
  box-sizing: border-box;
  /* disable library transition animations */
  --sjs-transition-duration: 0ms;
  @include disableLibraryAnimations;
}

.spg-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.spg-root-modern {
  height: 0;
  flex-grow: 1;
  overflow: auto;
}

.spg-root-modern.spg-root--one-category {
  background-color: var(--ctr-property-grid-form-background-color, $background-dim);
  border-right: unset;

  .spg-page.spg-body__page {
    box-shadow: unset;
  }
}



.sv-popup--modal {
  &.svc-property-editor .sv-popup__container {
    .sv-popup_shadow {
      background-color: transparent;
    }

    .sd-root-modern {
      background-color: transparent;
    }

    .sd-page {
      margin: 0;
      padding: 0;
    }

    .sv-components-column--expandable {
      min-width: calcSize(78);
    }
  }

  .sv-popup__footer {
    padding-bottom: 2px;
  }

  .sd-body,
  .sl-body {
    min-width: calcSize(78);
  }
}

.sv-popup.svc-property-editor .sd-root-modern {
  --sd-mobile-width: 0;
}

.svc-object-selector .sv-popup__container {
  margin-left: calcSize(1);
  margin-right: calcSize(1);
}

.svc-object-selector__content {
  position: relative;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.spg-action-title-bar {
  margin-inline-end: var(--ctr-data-table-border-width, 1px);
  padding-inline-end: var(--ctr-caption-with-actions-actionbar-padding-right, calcSize(0.5));
}

.spg-body {
  width: 100%;
}

.spg-body--empty {
  margin: calcSize(3);
}

.spg-title-toolbar--single-help-action {
  justify-content: flex-start;
  padding-left: calcSize(0.5);

  // flex-grow: 0;
  // margin-left: 0;
  // padding-left: 0;

  .spg-help-action {
    flex-grow: 1;

    .spg-action-button--icon {
      opacity: 0.5;
      padding: calcSize(0.5);
    }

    .spg-action-button--icon:focus,
    .spg-action-button--icon:hover {
      opacity: 1;
    }

    .spg-action-button:active {
      opacity: var(--ctr-actionbar-button-opacity-pressed, 0.5);
    }

    svg {
      fill: $foreground-dim-light;
    }
  }
}