.LongSummary{
    font-family: "Source Sans Pro";
    font-size:  16px;
    font-weight: 400;
    line-height: 18.52px;
    letter-spacing: 0px;
    margin-top: 10px;
    width: calc(100% - 84px);
}

.LongTitle{
    font-family: "Source Sans Pro";
    width: calc(100% - 84px);
    font-size: 24px;
    font-weight: 600;
}

.icon{
    position: relative;
}

.viewAllLink{
    color: #1976d2;
    line-height: 18.52px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    padding-top: 15px;
    justify-content: flex-end; /* Align items to the right */
}
.noDataMessage{
    font-family: Source Sans Pro;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 50px;
  }