 .normalLabel > span{
    color:black;
    font-weight: 600!important;      
    font-size:16px!important;    
    line-height:21.28px!important;    
  }

.selectedLabel > span {
    color:#0074E0;
    font-weight: 600!important;
    font-size:16px!important;    
    line-height: 21.28px!important;    
  }
 