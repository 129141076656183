.Main_container{
  width: 100%;
  /* min-width: 824px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UpcomingDatesHome_container {
  width: 100%;
}
.StatusForAllSites_container {
  width: 100%;
  margin-top: 20px;
}
.SiteInitiatives_container {
  width: 100%;
  margin-top: 20px;
}
.noDataContainer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}