.card{
    align-content: left!important;
    box-shadow: none!important;
    border: 1px solid #DDDDDD;
    border-radius: 8px!important;
    height: 100%!important;
    width: 100%!important;
    
}

.cardContent{
    margin: 0px 15px 0px 15px;
    min-height: 545px;
    display: flex;
    flex-direction: column;
    justify-content: space-between!important;
}

.cardTitle{
    margin-top: 20px!important;
    white-space: nowrap;
    overflow: hidden;  
}

.cardDescription{
    text-align: left;
    margin-top: 34px;
    min-height: 75px;  
}

.select{
    font-size: 14px!important;
    line-height: 140%!important;
    font-weight: 600!important;
    border-radius: 100px!important;
    color: #1976d2!important;
    height: 38px!important;
    text-align: center!important;
    width: 100%;
    &:focus {
        border-radius: 100px;
        border-color: #1976d2;
    }
}

.menuItem{
    font-size: "16px!important";
    background-color: transparent;
}

.exportBtn{
    padding-bottom: 43px;
}