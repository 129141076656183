.statusDraft {
  padding: 5px 11px;
  background: #d6ebff;
  border-radius: 8px;
  color: #1976d2;
  font-style: normal;
}
.statusPublished {
  padding: 5px 11px;
  background: #dcfae9;
  border-radius: 8px;
  color: #27ae60;
  font-style: normal;
}
.statusDeactivated {
  padding: 5px 11px;
  background: #ffdec6;
  border-radius: 8px;
  color: #ed7014;
  font-style: normal;
}

.statusExpired {
  padding: 5px 11px;
  background: #e7e7e7 !important;
  border-radius: 8px;
  color: #8b8b8b !important;
  font-style: normal;
}

.statusPending {
  padding: 5px 11px;
  background: #d6ebff;
  border-radius: 8px;
  color: #1976d2;
  font-style: normal;
}

.statusExpired {
  padding: 5px 11px;
  background: #dcfae9;
  border-radius: 8px;
  color: #27ae60;
  font-style: normal;
}
