.FormTitle{
    padding: 48px 48px 28px 48px!important;
    font-size: 32px!important;
    font-weight: 600!important;
}
.FormContent{
   padding: 0px 48px 0px 48px!important;
   overflow-y: hidden!important;
}
.FormAction{
    padding: 48px!important;
}
.FormMenuItem{
    color: #000000!important;
    font-size: 16px!important;
    font-weight: 400!important;
}
.FormSelect{
    height: 43px!important;
    border-radius: 20px!important;
}

.FormList{
    list-style: none!important;
    overflow-y: scroll!important;
    overflow-x: hidden!important;
    height: 104px!important;
    margin: 6px 0px 6px 0px!important;
    scrollbar-width: thin!important;
}
.FormListItem{
    padding: 5px!important;
    justify-content: normal!important;
    font-size: 16px!important;
    font-weight: 400!important;
    color: #000000!important;
    margin: 1px!important;
    width: 100%!important;
    border-width: 10px!important;
    cursor: pointer!important;
}

.FormLabel{
    color: #09101D!important;
    opacity:80%!important;
}