.select {
  font-size: 14px !important;
  line-height: 140% !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  color: #1976d2 !important;
  height: 38px !important;
  text-align: center !important;
  max-width: 220px;
  width: 100%;

  &:focus {
    border-radius: 100px;
    border-color: #1976d2;
  }
}
.menuItem {
  font-size: "16px!important";
  background-color: transparent;
}
.site_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.site_font{
  font-weight: 400;
  margin: 0px!important;
}