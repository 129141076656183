.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
  }
.enrollment_container{
  margin-top: 20px; /* Add appropriate margin as per your design */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.yearSelection{
  width: 140px!important;
}
.yearSelection >div{
  text-align: center!important;
}

