.uploadContainer {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 20px;
  border-color: #eeeeee;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
