.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
  }