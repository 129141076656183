.cardContainer {
    margin-top: 20px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    /* justify-content: space-between; */
    padding-top: 10px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
.initiativeContainer{
  margin-top: 20px; /* Add appropriate margin as per your design */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.initiativeGridcontainer{
    margin-top: 20px; /* Add appropriate margin as per your design */
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.initiativeGridcontainer{
    margin-top: 20px;

}
.tabs_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}