.initiativeCheckbox {
  display: table-row-group;
}
.CloseIconButton {
  position: absolute;
  right: 8px;
  cursor: pointer;
  padding-right: 57px;
  padding-bottom: 326px;
  padding-left: 738px;
  padding-top: 40px;
}

.container > div > div {
  min-width: 655px !important;
}

.closeIcon {
  position: absolute;
  margin: 48px 45px 20px 0px!important;
  width: 35px;
  height: 35px;
}

.enrollButton {
  max-width: 300px !important;
}

.cancelButton {
  max-width: 300px !important;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 20px;
  border-color: #eeeeee;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
