.card {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #dddddd;
  border-radius: 20px !important;
}

.container {
  padding: 20px !important;
}
.cardHeader {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  max-height: 36px;
}

.LongTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold !important;
  margin: 0;
}

.rowContainer {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
}

.noDataMessage {
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.select {
  font-size: 14px !important;
  line-height: 140% !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  color: #1976d2 !important;
  height: 38px !important;
  text-align: center !important;
  width: 100%;
  &:focus {
    border-radius: 100px;
    border-color: #1976d2;
  }
}

.menuItem {
  font-size: "16px!important";
  background-color: transparent;
}
.initiatives {
  margin-left: auto;
}
