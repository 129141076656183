.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}

.container_header {
    display: flex;
    justify-content: space-between;
}

.report_container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
     align-items: stretch; 
}
