.initiativeCheckbox{
    display: table-row-group;
  }
.CloseIconButton{
  position: absolute;
  right: 8px;
  cursor: pointer;
  padding-right: 57px;
  padding-bottom: 326px;
  padding-left: 738px;
  padding-top: 40px;
}

.enrollContatiner > div >div{
  width: 800px !important;
}

.closeIconUnenroll{
  left: 538px;
  top:25px;
  width: 35px;
  height: 35px;
}

.enrollButton{
  max-width: 300px!important;
}

.cancelButton{
  max-width: 300px!important;
}