.container {
  margin-bottom: 20px;
  border: 1px solid rgb(237, 235, 235);
  border-radius: 20px;
  box-shadow: none!important;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tableHeader {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
.tableHeaderName {
  flex: 3;
}
.tableHeaderStartDate {
  flex-grow: 1;
}
.tableHeaderEndDate {
  flex-grow: 1;
}
