.sidebarItem {
  display: list-item;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  width: 68px;
  height: auto;
  color: black;
  background-color: "#F0F0F0";
  transition: background-color 0.3s, color 0.3s;
  padding: 10px 0px;
}

.sidebarItemIcon {
  margin-bottom: 5px;
  text-align: center;
}

.sideItemText {
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: block;
  font-family: "Source Sans Pro";
}

.sidebarItem:hover {
  background-color: white !important;
  color: black;
}
.sidebarItemDisabled
{
  opacity:0.5;
  pointer-events:none !important;
}
.sidebarItemEnabled
{
  opacity:1;
  pointer-events:'auto'
}