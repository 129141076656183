.container_head{
    border: 1px solid #dddddd;
    border-radius: 20px;
    width: 100%;
}
.container_header{
    display: flex;
    align-items: center;
    margin: 20px 20px 0px 20px;
}
.title{
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    width: 100%;
}
.select_container{
    width: 50%;
    display: flex;
    justify-content: flex-end;

}
.select{
    font-size: 14px!important;
    line-height: 140%!important;
    font-weight: 600!important;
    border-radius: 100px!important;
    color: #1976d2!important;
    height: 38px!important;
    text-align: center!important;
    &:focus {
        border-radius: 100px;
        border-color: #1976d2;
    }
}
.menuItem{
    font-size: "16px!important";
    background-color: transparent;
}