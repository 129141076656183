.siteInitiativeGridcontainer {
  /* margin-top: 20px; Add appropriate margin as per your design */
  display: flex;
  /* flex-direction: row;
  align-items: stretch; */
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
}

.card {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #dddddd;
  border-radius: 20px !important;

}

.container {
  padding: 20px !important;
}

.LongTitle {
  font-size: 28px;
  line-height: 34px;
  font-weight: bold !important;
}
