@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

/*This css will be usefulf for all the utilize the available width of the parent
 container. In case if we wanted to show scroll in one of the scenario then we can use
 :not(.className) so below css won't apply for the specific data table  */

@media (max-height: 500px) {
  [role="dialog"] h2:first-of-type {
    padding: 10px 0px 10px 55px !important;
  }
}

.react-data-table > div:first-child {
  display: block !important;
}
* {
  font-family: "Source Sans Pro" !important;
}

ol,
ul {
  list-style: none;
  text-align: left;
  padding: 0px;
}

li {
  line-height: 30px;
}

a {
  text-decoration: none;
}

.myDatePicker div {
  border-radius: 20px !important;
}
.myDatePicker svg {
  color: black !important;
}

div[role="presentation"] .MuiPaper-rounded {
  border: 1px solid !important;
  border-color: #dddddd !important;
  border-radius: 8px !important;
}

/* div[role="presentation"] {
  z-index: 9999 !important;
} */

#add-existing-champions .MuiPaper-rounded {
  border: none !important;
}

.acep-id,
.member-status,
.divAccordian,
.login-btn,
.logout-btn,
.header-text {
  font-family: Source Sans Pro !important;
}
.login-btn a {
  color: #333333 !important;
}
Button {
  border-radius: 100px !important;
}
.memberToolopen {
  width: 310px !important;
  left: -36px !important;
}
/* This Style is used to hide the dropdown question choices  
.svc-question__content--dropdown .svc-question__dropdown-choices--wrapper {
  display: none;
} */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerMaxWidth {
  max-width: 1200px !important;
}
/* This is for set resonsive Grid in questionare */
.initiativeQues .Container_MUIGrid {
  display: contents;
}
#svd-grid-object-selector {
  margin-right: 20px;
  overflow: hidden;
}

#svd-grid-object-selector .sv-action-bar-item__title {
  max-width: 356px;
  margin-right: 5px;
}
#svd-preview {
  display: none;
}
