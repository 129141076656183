.container_head{
    border: 1px solid #dddddd;
    border-radius: 20px;
    width: 100%;
}
.title{
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    width: 50%;
    margin: 20px 0 20px 20px;
}
.gridContainer{
    margin: 20px 20px 35px 20px;
    max-width: 100%;
}