.card {
  width: 100%;
  box-shadow: none !important;
  border: 1px solid #dddddd;
  border-radius: 20px !important;
}

.container {
  padding: 20px !important;
  width: 100%;
  /* min-width: 824px; */
}

.LongTitle {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold !important;
}