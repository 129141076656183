.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.container_header {
  display: flex;
  justify-content: space-between;
}
.container_back {
  margin-bottom: 20px;
  margin-left: 24px;
}

.tabLabel {
  display: flex;
  align-items: center; /* Vertically center align icon and text */
}

.iconContainer {
  display: flex;
  align-items: center; /* Center the icon vertically */
  margin-right: 8px; /* Add some space between the icon and text */
}

.icon {
  width: 30px;
  height: 30px;
}
.activeTab {
  color: #000000; /* Black color when active */
}

.disabledTab {
  color: #cccccc; /* Gray color when disabled */
}
