.ListItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding-bottom: 0px;
}

.ListItemRow {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.container {
  flex: 2; 
}

.name {
  font-weight: 600;
  display: flex;
  line-height: 25px;
  font-size: 25px;
}
