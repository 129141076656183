.select {
    font-size: 14px !important;
    line-height: 140% !important;
    font-weight: 600 !important;
    border-radius: 100px !important;
    color: #1976d2 !important;
    height: 38px !important;
    text-align: center !important;
    max-width: 220px;
    width: 100%;

    &:focus {
        border-radius: 100px;
        border-color: #1976d2;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
   
}

.tableContainer {
    margin-top: 20px;
}
.downloadButton{
    min-width: 150px!important;
    white-space: nowrap;
  }