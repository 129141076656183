.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
  
  .container_header {
    display: flex;
    justify-content: space-between;
  }
.enrollment_container{
  margin-top: 20px; /* Add appropriate margin as per your design */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.initiativeButton{
  min-width: 220px !important;  
}
.searchButton{
  max-width: 110px!important;
}
.searchBoxContainer{
  display: flex!important;
  align-items: center!important;
}
.searchButtonContainer{
flex: 0 0 auto!important;
display: flex!important;
align-items: center!important;
margin-left: 10px!important;
}