  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}  
.container_header {
    display: flex;
    justify-content: space-between;
}
.championContainer{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.manageChampionGridcontainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.addChampion{
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}